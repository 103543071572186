import { SyncService } from "../services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { useCanvasSettingsData } from "frontend/subscriptions";
import { CanvasSetting, CanvasSettingsSchema } from "shared/datamodel/schemas/canvas-settings";

export function useCanvasSettings(syncService: SyncService<RW>) {
  const defaultSettings: CanvasSettingsSchema = {
    showCursors: true,
  };

  const settings: CanvasSettingsSchema | null = useCanvasSettingsData(syncService?.getReplicache()) ?? defaultSettings;

  function getSetting(setting: CanvasSetting) {
    return settings?.[setting];
  }

  function updateSetting(setting: CanvasSetting, value: any) {
    syncService?.mutate.updateSetting({ setting, value });
  }

  return {
    settings,
    getSetting,
    updateSetting,
  };
}
